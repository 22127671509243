<template>
    <div class="search-page-background">
        <header-bar target-route="SpiritScanRoute" />
        <div>
            <div class="light-text">
                <span v-if="spirits.length > 0">
                    <span class="bold-text">{{ spirits.length }}</span>
                    {{ $t('SpiritSearchRoute.searchResult') }}
                </span>
                <span v-else class="bold-text">
                    <div>
                        {{ $t('SpiritSearchRoute.productsNotFound[0]') }}
                    </div>
                    <div>
                        {{ $t('SpiritSearchRoute.productsNotFound[1]') }}
                    </div>
                </span>
            </div>
            <div class="search-container">
                <div
                    v-for="spirit in spirits"
                    :key="spirit.id + spirits.length"
                    cols="4"
                    class="pb-4 mb-4 mt-4"
                    @click="viewSpirit(spirit)"
                >
                    <spirit-list-item :spirit="spirit" />
                </div>
            </div>
        </div>
        <enjoy-responsibly />
        <disclaimer :background-color="'#f4ead8'" />
    </div>
</template>

<script>
    import SpiritListItem from '@/components/spirit-mastery/SpiritListItem'
    import HeaderBar from '@/components/HeaderBar'
    import EnjoyResponsibly from '@/components/EnjoyResponsibly'
    import Disclaimer from '@/components/Disclaimer'

    export default {
        components: {
            SpiritListItem,
            HeaderBar,
            Disclaimer,
            EnjoyResponsibly
        },
        computed: {
            spirits() {
                return this.$store.getters.spiritSearchResults
            },
            searchText() {
                return this.$store.getters.spiritSearchQuery
            }
        },
        watch: {
            searchText: {
                immediate: true,
                handler() {
                    this.$store.dispatch('searchSpirits', this.searchText)
                }
            }
        },
        created() {},
        methods: {
            viewSpirit(spirit) {
                this.$router.push({
                    name: 'SpiritMasterySpiritRoute',
                    params: {
                        id: spirit.id,
                        spirit: spirit
                    },
                    query: {
                        fromSearch: true
                    }
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
.search-page-background {
  background: url('~@/assets/spirit-mastery/background_spirit_info.png');
  width: 100%;
}

.light-text {
  text-align: center;
  padding-top: 50px;
  margin-bottom: 50px;
  font-family: CFALight;
  color: #7f2f2d;
  font-size: clamp(30px, 4.6vw, 50px);
}

.bold-text {
  font-family: CFABold;
}

.search-container {
  padding-bottom: 100px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  overflow: auto;
  height: 100vh;
}
</style>
