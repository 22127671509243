<template>
    <div class="home-container" @click="focusInput">
        <div class="head-section pt-3">
            <img src="@/assets/home-route/wine_and_spirits_mastery.png">
        </div>
        <router-link v-slot="{ navigate }" to="/wine-roads" custom>
            <div role="link" class="wine-roads" @click="navigate" @keypress.enter="navigate">
                <div class="title pt-5">
                    {{ $t('HomeRoute.wineRoads') }}
                </div>
                <pre class="description mt-4">{{ $t('HomeRoute.wineRoadsText') }}</pre>
                <img class="mt-1" src="@/assets/home-route/tile-gold-arrow.png">
            </div>
        </router-link>
        <router-link v-slot="{ navigate }" to="/sommelier" custom>
            <div role="link" class="sommelier-section" @click="navigate" @keypress.enter="navigate">
                <div class="float-right">
                    <div class="title pt-5">
                        {{ $t('HomeRoute.sommelier') }}
                    </div>
                    <pre class="description mt-4">{{ $t('HomeRoute.sommelierText') }}</pre>
                    <img class="mt-1" src="@/assets/home-route/tile-gold-arrow.png">
                </div>
            </div>
        </router-link>
        <router-link v-slot="{ navigate }" to="/wine-mastery" custom>
            <div role="link" class="wine-mastery-section" @click="navigate" @keypress.enter="navigate">
                <div class="title pt-5">
                    {{ $t('HomeRoute.wineMastery') }}
                </div>
                <pre class="description mt-4">{{ $t('HomeRoute.wineMasteryText') }}</pre>
                <img class="mt-1" src="@/assets/common/tile-light-arrow.png">
            </div>
        </router-link>
        <router-link v-slot="{ navigate }" to="/spirit-mastery" custom>
            <div role="link" class="spirits-section" @click="navigate" @keypress.enter="navigate">
                <div class="float-right">
                    <div class="title pt-5">
                        {{ $t('HomeRoute.spirits') }}
                    </div>
                    <pre class="description mt-4">{{ $t('HomeRoute.spiritsText') }}</pre>
                    <img class="mt-1" src="@/assets/home-route/tile-black-arrow.png">
                </div>
            </div>
        </router-link>
        <div class="footer-section pt-5">
            <pre class="main">{{ $t('HomeRoute.footerMainText') }}</pre>
            <pre class="info">{{ $t('HomeRoute.footerInfoText') }}</pre>
            <div class="disclaimer float-right">{{ $t('EnjoyResponsibly.enjoyResponsibly') }}</div>
        </div>
        <div v-if="scanWasNotFound" class="popup">
            <div class="background">
                <div class="body">
                    <div class="close2" @click="closePopup">
                        <img :src="require('@/assets/wine-mastery/back_button.png')">
                    </div>
                    <div class="container">
                        <div class="scan-indicator">
                            <img :src="require('@/assets/wine-mastery/scan_not_found.png')">
                        </div>
                        <span>{{ $t('HomeRoute.productNotFound') }} <br> {{ $t('HomeRoute.tryAgain') }} </span>
                    </div>
                </div>
            </div>
        </div>
        <input
            ref="barcode"
            v-model="barcode"
            type="text"
            class="barcode-input"
            @keydown.enter="scan"
        >
    </div>
</template>
<script>
    import scanService from '@/services/scan-service'

    export default {
        data() {
            return {
                scanned: null,
                scanWasNotFound: false,
                barcode: '',
                timeOut: null
            }
        },
        watch: {
            scanned(newVal) {
                if (newVal.type === 'spirit') {
                    this.$router.push({ path: `/spirit-mastery/${newVal.id}`, query: { fromScan: true } })
                } else if (newVal.type === 'wine') {
                    this.$router.push({ path: `/wine-mastery/wines/${newVal.id}`, query: { fromScan: true } })
                }
            }
        },
        mounted() {
            this.focusInput()
        },
        methods: {
            focusInput() {
                if (this.$refs.barcode) {
                    this.$refs.barcode.focus()
                }
            },
            scan() {
                scanService.scan(this.barcode).then(scanned => {
                    this.scanned = scanned
                }).catch(() => {
                    this.displayNotFound()
                })
            },
            displayNotFound() {
                this.scanWasNotFound = true
                this.barcode = ''
                clearTimeout(this.timeOut)
                this.timeOut = setTimeout(() => {
                    this.scanWasNotFound = false
                }, 5 * 1000)
            },
            closePopup() {
                this.scanWasNotFound = false
            }
        }
    }
</script>
<style scoped>
.home-container {
    width: 1080px;
    height: 1920px;
}
.head-section {
    background: #383533;
    background-repeat: no-repeat;
    background-size: auto;
    height: 173px;
}
.title {
    font-size: 1.375rem;
    font-family: "CFABold";
}
.description {
    font-size: 2.375rem;
    font-family: "CFABold";
    line-height: 43px;
}
.wine-roads {
    background: url('~@/assets/home-route/wine_roads_background.png');
    background-repeat: no-repeat;
    background-size: auto;
    height: 373px;
    padding-left: 60px;
}
.wine-roads .title {
    color: #838281;
}
.wine-roads .description {
    color: #be884f;
}
.sommelier-section {
    background: url('~@/assets/home-route/ab_sommelier_background.jpg');
    background-repeat: no-repeat;
    background-size: auto;
    height: 372px;
    padding-right: 60px;
}
.sommelier-section .title {
    color: #c39646;
}
.sommelier-section .description {
    color: #fff;
}
.wine-mastery-section {
    background: url('~@/assets/home-route/wine_mastery_background.jpg') #bb7d34;
    background-repeat: no-repeat;
    background-size: auto;
    background-position: right;
    height: 373px;
    padding-left: 60px;
}
.wine-mastery-section .title {
    color: #fff;
}
.wine-mastery-section .description {
    color: #f1c57f;
}
.spirits-section {
    background: url('~@/assets/home-route/spiritis_background.jpg');
    height: 373px;
    background-repeat: no-repeat;
    background-size: auto;
    padding-right: 100px;
}
.spirits-section .title {
    color: #e08b46;
}
.spirits-section .description {
    color: #33312e;
}
.footer-section {
    background: url('~@/assets/home-route/footer_background.jpg');
    background-repeat: no-repeat;
    background-size: auto;
    height: 256px;
    text-align:center;
    position: relative;
}
.main {
    color: #fff;
    font-size: 2.25rem;
    font-family: "CFABold";
    line-height: 43px;
}
.info {
    color: #8d3131;
    font-size: 1.75rem;
    font-family: "CFABold";
    line-height: 35px;
}
.disclaimer {
    font-size: 0.813rem;
    color: #f2e4d5;
    font-family: "CFALight";
    position: absolute;
    right: 15px;
    bottom: 5px;
}
.barcode-input {
    opacity: 0;
    position: absolute;
    top: -20px;
    left: -20px;
}

.popup {
  position: relative;
}

.popup .background {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  background: rgba(0, 0, 0, .6);
}

.popup .body {
  background: url('~@/assets/common/background_image.png');
  background-size: cover;
  padding: 2.81rem 2.5rem;
  margin:  5.4rem auto;
  border-radius: 0.625rem;
  box-shadow: 0 1.125rem 3.69rem rgba(0,0,0,0.54);
  line-height: 4.75rem;
  font-weight: 300;
  width: 85vw;
  height: 90vh;
  max-width: 1300px;
  max-height: 1920px;
}
.popup .body span {
  display: inline-block;
  vertical-align: middle;
  line-height: normal;
  margin-top: 5%;
  color: #ac3846;
  font-size: 2rem;
  text-align: center;
  font-family: CFABold;
}

.popup .close2 {
  float: right;
  cursor: pointer;
}

.close2 img {
  width: 3.75rem;
  height: 3.75rem;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.scan-indicator {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 8rem;
}

.scan-indicator img{
  height: 43rem;
  margin-top: 6.5rem;
  margin-bottom: 6.875rem;
}

</style>
