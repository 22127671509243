<template>
    <div v-if="slideImages" class="slideshow-container" @click="exitSlideShow()">
        <vueper-slides
            ref="myVueperSlides"
            autoplay
            :pause-on-hover="false"
            fixed-height="1920px"
            :arrows="false"
            :touchable="false"
            :bullets="false"
            :duration="10000"
        >
            <vueper-slide
                v-for="(slide) in slideImages"
                :key="slide.id"
                :image="imgSrc(slide.image)"
                :style="'height: 1920px; background-color: #f8f7f3;'"
                :content="slide.content"
            />
        </vueper-slides>
    </div>
</template>

<script>
    import { VueperSlides, VueperSlide } from 'vueperslides'
    import 'vueperslides/dist/vueperslides.css'
    import slideService from '@/services/landing-slideshow-service'
    import config from '@/mixins/config'
    export default {
        components: {
            VueperSlides,
            VueperSlide
        },
        mixins: [config],
        data() {
            return {
                slideImages: [
                ]
            }
        },
        created() {
            this.fetchAndSortSlideImages()
            if (this.$route.params.kioskId) {
                localStorage.setItem('kioskId', this.$route.params.kioskId)
            }
        },
        methods: {
            fetchAndSortSlideImages() {
                slideService.fetchSlideImages().then(response => {
                    this.slideImages = response.content
                })
            },
            imgSrc(image) {
                return `${this.config.host}/${image.relativePath}/${image.fileName}`
            },
            exitSlideShow() {
                this.$router.push('/home')
            }
        }
    }
</script>

<style scoped>
.slideshow-container {
    width: 1080px;
}
</style>
