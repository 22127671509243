import Vue from 'vue'
import Router from 'vue-router'
import LandingSlide from '@/components/LandingSlideShow'
import HomeRoute from '@/components/HomeRoute'
import SommelierRoute from '@/components/sommelier/SommelierRoute'
import WineRoadsRoute from '@/components/wine-roads/WineRoadsRoute'
import WineRoadsStep1Route from '@/components/wine-roads/WineRoadsStep1Route'
import WineRoadsStep2Route from '@/components/wine-roads/WineRoadsStep2Route'
import WineRoadsStep3Route from '@/components/wine-roads/WineRoadsStep3Route'
import WineRoadsStep3AllWinesRoute from '@/components/wine-roads/WineRoadsStep3AllWinesRoute'
import WineRoadsWineRoute from '@/components/wine-roads/WineRoadsWineRoute'
import WineMasteryRoute from '@/components/wine-mastery/WineMasteryRoute'
import WineFoodCombinationRoute from '@/components/sommelier/WineFoodCombinationRoute'
import WineSecretsRoute from '@/components/sommelier/WineSecretsRoute'
import WineSecretDetailRoute from '@/components/sommelier/WineSecretDetailRoute'
import WineScanRoute from '@/components/wine-mastery/WineScanRoute'
import SpiritScanRoute from '@/components/spirit-mastery/SpiritScanRoute'
import WineMasteryWineRoute from '@/components/wine-mastery/WineMasteryWineRoute'
import WineFoodCombinationWineRoute from '@/components/sommelier/WineFoodCombinationWineRoute'
import WineSearchRoute from '@/components/wine-mastery/WineSearchRoute'
import SpiritSearchRoute from '@/components/spirit-mastery/SpiritSearchRoute'
import SpiritMasteryRoute from '@/components/spirit-mastery/SpiritMasteryRoute'
import SpiritMasterySpiritRoute from '@/components/spirit-mastery/SpiritMasterySpiritRoute'
import WineFoodCombinationStep1Route from '@/components/sommelier/WineFoodCombinationStep1Route'
import WineFoodCombinationStep1AllWinesRoute from '@/components/sommelier/WineFoodCombinationStep1AllWinesRoute'
import WineFoodCombinationStep2Route from '@/components/sommelier/WineFoodCombinationStep2Route'

import idler from '@/utils/idler'
import vueConfig from '@/../vue.config'

Vue.use(Router)
const router = new Router({
    mode: 'history',
    base: vueConfig.publicPath,
    routes: [
        {
            path: '/',
            name: 'LandingSlide',
            alias: '*',
            component: LandingSlide,
            meta: {
                title: 'Home'
            }
        },
        {
            path: '/home',
            name: 'HomeRoute',
            component: HomeRoute,
            meta: {
                title: 'Home'
            }
        },
        {
            path: '/kioskid/:kioskId',
            name: 'KioskIDPage',
            component: LandingSlide,
            meta: {
                title: 'Home'
            }
        },
        {
            path: '/wine-roads',
            name: 'WineRoadsRoute',
            component: WineRoadsRoute,
            meta: {
                title: 'WineRoadsRoute'
            }
        },
        {
            path: '/wine-roads/1/:regionId',
            name: 'WineRoadsStep1Route',
            component: WineRoadsStep1Route,
            meta: {
                title: 'WineRoadsStep1Route'
            }
        },
        {
            path: '/wine-roads/2/:regionId',
            name: 'WineRoadsStep2Route',
            component: WineRoadsStep2Route,
            meta: {
                title: 'WineRoadsStep2Route'
            }
        },
        {
            path: '/wine-roads/3/:regionId',
            name: 'WineRoadsStep3Route',
            component: WineRoadsStep3Route,
            meta: {
                title: 'WineRoadsStep3Route'
            }
        },
        {
            path: '/wine-roads/3/:regionId/all-wines',
            name: 'WineRoadsStep3AllWinesRoute',
            component: WineRoadsStep3AllWinesRoute,
            meta: {
                title: 'WineRoadsStep3AllWinesRoute'
            }
        },
        {
            path: '/wine-roads/wines/:id',
            name: 'WineRoadsWineRoute',
            component: WineRoadsWineRoute,
            meta: {
                title: 'WineRoadsWineRoute',
                header: true
            }
        },
        {
            path: '/sommelier',
            name: 'SommelierRoute',
            component: SommelierRoute,
            meta: {
                title: 'SommelierRoute'
            }
        },
        {
            path: '/sommelier/wine-secrets',
            name: 'Sommelier Wine Secrets',
            component: WineSecretsRoute,
            meta: {
                title: 'WineSecretsRoute'
            }
        },
        {
            path: '/sommelier/wine-secrets/:secretId',
            name: 'Sommelier Wine Secret Detail',
            component: WineSecretDetailRoute,
            meta: {
                title: 'WineSecretDetailRoute'
            }
        },
        {
            path: '/sommelier/wine-food-combinations',
            name: 'Sommelier Wine Food Route',
            component: WineFoodCombinationRoute,
            meta: {
                title: 'WineFoodCombinationRoute'
            }
        },
        {
            path: '/sommelier/wine-food-combinations/wines/:id',
            name: 'WineFoodCombinationWineRoute',
            component: WineFoodCombinationWineRoute,
            meta: {
                title: 'WineFoodCombinationWineRoute',
                header: true
            }
        },
        {
            path: '/sommelier/wine-food-combinations/1/:foodId',
            name: 'Sommelier Wine Food Combination Step 1 Route',
            component: WineFoodCombinationStep1Route,
            meta: {
                title: 'WineFoodCombinationStep1Route'
            }
        },
        {
            path: '/sommelier/wine-food-combinations/1/:foodId/all-wines',
            name: 'Sommelier Wine Food Combination Step 1 All Wines Route',
            component: WineFoodCombinationStep1AllWinesRoute,
            meta: {
                title: 'WineFoodCombinationStep1AllWinesRoute'
            }
        },
        {
            path: '/sommelier/wine-food-combinations/2/:foodId',
            name: 'Sommelier Wine Food Combination Step 2 Route',
            component: WineFoodCombinationStep2Route,
            meta: {
                title: 'WineFoodCombinationStep2Route'
            }
        },
        {
            path: '/wine-mastery',
            name: 'WineMasteryRoute',
            component: WineMasteryRoute,
            meta: {
                title: 'WineMasteryRoute'
            }
        },
        {
            path: '/wine-mastery/wines/search',
            name: 'WineSearchRoute',
            component: WineSearchRoute,
            meta: {
                title: 'WineSearchRoute',
                header: true
            },
            props: (route) => ({
                ...route.params
            })
        },
        {
            path: '/wine-mastery/wines/scan',
            name: 'WineScanRoute',
            component: WineScanRoute,
            meta: {
                title: 'WineScanRoute',
                historySkip: true
            }
        },
        {
            path: '/wine-mastery/wines/:id',
            name: 'WineMasteryWineRoute',
            component: WineMasteryWineRoute,
            meta: {
                title: 'WineMasteryWineRoute',
                header: true
            }
        },
        {
            path: '/spirit-mastery',
            name: 'SpiritMasteryRoute',
            component: SpiritMasteryRoute,
            meta: {
                title: 'SpiritMasteryRoute',
                header: false
            }
        },
        {
            path: '/spirit-mastery/:id',
            name: 'SpiritMasterySpiritRoute',
            component: SpiritMasterySpiritRoute,
            meta: {
                title: 'SpiritMasterySpiritRoute',
                header: true
            }
        },
        {
            path: '/spirit-mastery/scan',
            name: 'SpiritScanRoute',
            component: SpiritScanRoute,
            meta: {
                title: 'SpiritScanRoute',
                historySkip: true
            }
        },
        {
            path: '/spirit-mastery/spirits/search',
            name: 'SpiritSearchRoute',
            component: SpiritSearchRoute,
            meta: {
                title: 'SpiritSearchRoute',
                header: true
            },
            props: (route) => ({
                ...route.params
            })
        }
    ]
})

router.beforeResolve((to, from, next) => {
    if (to.name === 'WineScanRoute' && !to.params.fromHeader) {
        router.go(-1)
    } else {
        next()
    }
})

router.afterEach((to, from) => {
    idler.reset()
})

export default router
