import { library } from '@fortawesome/fontawesome-svg-core'
import { faAngleRight, faAngleLeft, faAngleDown, faTimes, faSearch, faBell, faUser, faPlayCircle, faFileDownload, faShoppingBasket, faTrashAlt, faChevronUp, faGreaterThan } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import App from '@/App'
import Vuex from 'vuex'
import BootstrapVue from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import store from '@/store'
import Promise from 'promise-polyfill'
import router from '@/router'
import el from '@/locale/el.json'
import VuePaginate from 'vue-paginate'
import Vue2TouchEvents from 'vue2-touch-events'
import { nullCheck, avatarBuilder } from '@/filters/filters'
const VueScrollTo = require('vue-scrollto')

if (!window.Promise) {
    window.Promise = Promise
}

library.add(faAngleRight, faAngleLeft, faAngleDown, faSearch, faTimes, faBell, faUser, faPlayCircle, faFileDownload, faShoppingBasket, faTrashAlt, faGreaterThan, faChevronUp)
Vue.component('FontAwesomeIcon', FontAwesomeIcon)
Vue.use(BootstrapVue)
Vue.use(Vuex)
Vue.use(VueI18n)
Vue.use(VueScrollTo)
Vue.use(VuePaginate)
Vue.use(Vue2TouchEvents)

Vue.filter('nullCheck', nullCheck)
Vue.filter('avatarBuilder', avatarBuilder)

const messages = { el }
const i18n = new VueI18n({
    locale: 'el',
    messages
})

Vue.config.productionTip = false

new Vue({
    store,
    router,
    i18n,
    render: h => h(App)
}).$mount('#app')
