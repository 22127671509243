var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home-container",on:{"click":_vm.focusInput}},[_vm._m(0),_c('router-link',{attrs:{"to":"/wine-roads","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('div',{staticClass:"wine-roads",attrs:{"role":"link"},on:{"click":navigate,"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return navigate($event)}}},[_c('div',{staticClass:"title pt-5"},[_vm._v(" "+_vm._s(_vm.$t('HomeRoute.wineRoads'))+" ")]),_c('pre',{staticClass:"description mt-4"},[_vm._v(_vm._s(_vm.$t('HomeRoute.wineRoadsText')))]),_c('img',{staticClass:"mt-1",attrs:{"src":require("@/assets/home-route/tile-gold-arrow.png")}})])]}}])}),_c('router-link',{attrs:{"to":"/sommelier","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('div',{staticClass:"sommelier-section",attrs:{"role":"link"},on:{"click":navigate,"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return navigate($event)}}},[_c('div',{staticClass:"float-right"},[_c('div',{staticClass:"title pt-5"},[_vm._v(" "+_vm._s(_vm.$t('HomeRoute.sommelier'))+" ")]),_c('pre',{staticClass:"description mt-4"},[_vm._v(_vm._s(_vm.$t('HomeRoute.sommelierText')))]),_c('img',{staticClass:"mt-1",attrs:{"src":require("@/assets/home-route/tile-gold-arrow.png")}})])])]}}])}),_c('router-link',{attrs:{"to":"/wine-mastery","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('div',{staticClass:"wine-mastery-section",attrs:{"role":"link"},on:{"click":navigate,"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return navigate($event)}}},[_c('div',{staticClass:"title pt-5"},[_vm._v(" "+_vm._s(_vm.$t('HomeRoute.wineMastery'))+" ")]),_c('pre',{staticClass:"description mt-4"},[_vm._v(_vm._s(_vm.$t('HomeRoute.wineMasteryText')))]),_c('img',{staticClass:"mt-1",attrs:{"src":require("@/assets/common/tile-light-arrow.png")}})])]}}])}),_c('router-link',{attrs:{"to":"/spirit-mastery","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('div',{staticClass:"spirits-section",attrs:{"role":"link"},on:{"click":navigate,"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return navigate($event)}}},[_c('div',{staticClass:"float-right"},[_c('div',{staticClass:"title pt-5"},[_vm._v(" "+_vm._s(_vm.$t('HomeRoute.spirits'))+" ")]),_c('pre',{staticClass:"description mt-4"},[_vm._v(_vm._s(_vm.$t('HomeRoute.spiritsText')))]),_c('img',{staticClass:"mt-1",attrs:{"src":require("@/assets/home-route/tile-black-arrow.png")}})])])]}}])}),_c('div',{staticClass:"footer-section pt-5"},[_c('pre',{staticClass:"main"},[_vm._v(_vm._s(_vm.$t('HomeRoute.footerMainText')))]),_c('pre',{staticClass:"info"},[_vm._v(_vm._s(_vm.$t('HomeRoute.footerInfoText')))]),_c('div',{staticClass:"disclaimer float-right"},[_vm._v(_vm._s(_vm.$t('EnjoyResponsibly.enjoyResponsibly')))])]),(_vm.scanWasNotFound)?_c('div',{staticClass:"popup"},[_c('div',{staticClass:"background"},[_c('div',{staticClass:"body"},[_c('div',{staticClass:"close2",on:{"click":_vm.closePopup}},[_c('img',{attrs:{"src":require('@/assets/wine-mastery/back_button.png')}})]),_c('div',{staticClass:"container"},[_c('div',{staticClass:"scan-indicator"},[_c('img',{attrs:{"src":require('@/assets/wine-mastery/scan_not_found.png')}})]),_c('span',[_vm._v(_vm._s(_vm.$t('HomeRoute.productNotFound'))+" "),_c('br'),_vm._v(" "+_vm._s(_vm.$t('HomeRoute.tryAgain'))+" ")])])])])]):_vm._e(),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.barcode),expression:"barcode"}],ref:"barcode",staticClass:"barcode-input",attrs:{"type":"text"},domProps:{"value":(_vm.barcode)},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.scan($event)},"input":function($event){if($event.target.composing){ return; }_vm.barcode=$event.target.value}}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"head-section pt-3"},[_c('img',{attrs:{"src":require("@/assets/home-route/wine_and_spirits_mastery.png")}})])}]

export { render, staticRenderFns }