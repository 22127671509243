import Vue from 'vue'
import Vuex from 'vuex'
import wineFilters from '@/store/modules/wineFilters'
import wines from '@/store/modules/wines'
import wineSearch from '@/store/modules/wineSearch'
import spiritSearch from '@/store/modules/spiritSearch'
import keyboard from '@/store/modules/keyboard'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        wineFilters: wineFilters,
        wines: wines,
        wineSearch: wineSearch,
        spiritSearch: spiritSearch,
        keyboard: keyboard
    }
})
