<template>
    <div
        id="app"
        @mousedown="clickEvent()"
    >
        <key-board v-show="$store.getters.keyboardVisible" />
        <router-view />
    </div>
</template>

<script>
    import idler from '@/utils/idler'
    import KeyBoard from '@/components/Keyboard'

    export default {
        components: {
            KeyBoard
        },
        data() {
            return {
                input: '',
                reloadAppTimer: null
            }
        },
        mounted() {
            this.resetReloadAppTimer()
        },
        destroyed() {
            clearInterval(this.reloadAppTimer)
        },
        methods: {
            resetReloadAppTimer() {
                clearInterval(this.reloadAppTimer)

                this.reloadAppTimer = setInterval(() => {
                    fetch(window.location).then(() => {
                        location.reload()
                    }).catch(() => {})
                }, 10 * 60 * 1000)
            },
            clickEvent() {
                this.resetReloadAppTimer()
                idler.reset()
            }
        }
    }
</script>

<style>
    html, body {
        width: 100%;
        margin: 0px;    /* ios only */
        padding: 0px;   /* ios only */
        height: 100%;
    }
    #app {
        width: 100%;
        margin: 0px;    /* ios only */
        padding: 0px;   /* ios only */
        height: 100%;
        /* overflow-y: hidden; */
    }

    .button {
        background: #010101;
        border: 4px solid #3a1c06;
        padding: 26px 20px;
        color: #fff;
        font-size: 32px;
        border-radius: 10px;
        box-shadow: 0px 0px 10px 1px rgb(0, 0, 0);
        cursor: pointer;
    }

    .button-circle {
        background: #fff;
        border: 4px solid #3a1c06;
        color: #606061;
        border-radius: 50%;
        height: 60px;
        width: 60px;
        text-align: center;
        padding: 2px 2px;
        font-size: 2em;
        box-shadow: 0px 0px 10px 1px rgb(0, 0, 0);
        cursor: pointer;
    }

    .text-input {
        background: #7e2d2b;
        color: #fff;
        border: 4px solid #b17f47;
        border-radius: 10px;
        padding: 12px;
    }

    ::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 8px;
        transform: translateZ(0);
    }
    ::-webkit-scrollbar-thumb {
        background: #a69272;
        border-radius: 10px;
    }

    ::-webkit-scrollbar-track {
        background: transparent;
        border-radius: 10px;
    }

    @font-face {
        font-family: "CFABold";
        src: url("~@/assets/fonts/CFAstyStd-Bold.eot") format('embedded-opentype'),
        url("~@/assets/fonts/CFAstyStd-Bold.ttf") format("trueType"),
        url("~@/assets/fonts/CFAstyStd-Bold.woff") format("woff")
    }

    @font-face {
        font-family: "CFALight";
        src: url("~@/assets/fonts/CFAstyStd-Light.eot") format('embedded-opentype'),
        url("~@/assets/fonts/CFAstyStd-Light.ttf") format("trueType"),
        url("~@/assets/fonts/CFAstyStd-Light.woff") format("woff")
    }

</style>
