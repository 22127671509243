<template>
    <div class="search-page-background">
        <header-bar />
        <div>
            <div class="light-text">
                <span v-if="wines.length > 0">
                    <span class="bold-text">{{ wines.length }}</span>
                    {{ $t('WineSearchRoute.searchResult') }}
                </span>
                <span v-else class="bold-text">
                    <div>
                        {{ $t('WineSearchRoute.productsNotFound[0]') }}
                    </div>
                    <div>
                        {{ $t('WineSearchRoute.productsNotFound[1]') }}
                    </div>
                </span>
            </div>
            <div class="search-container">
                <div
                    v-for="wine in wines"
                    :key="wine.id + wines.length"
                    cols="4"
                    class="pb-4 mb-4 mt-4"
                    @click="viewWine(wine)"
                >
                    <wine-list-item :wine="wine" />
                </div>
            </div>
        </div>
        <enjoy-responsibly />
        <disclaimer :background-color="'#f5f5f5'" />
    </div>
</template>

<script>
    import WineListItem from '@/components/wine-mastery/WineListItem'
    import HeaderBar from '@/components/HeaderBar'
    import Disclaimer from '@/components/Disclaimer'
    import EnjoyResponsibly from '@/components/EnjoyResponsibly'

    export default {
        components: {
            WineListItem,
            HeaderBar,
            Disclaimer,
            EnjoyResponsibly
        },
        computed: {
            wines() {
                return this.$store.getters.wineSearchResults
            },
            searchText() {
                return this.$store.getters.wineSearchQuery
            }
        },
        watch: {
            searchText: {
                immediate: true,
                handler() {
                    this.$store.dispatch('searchWines', this.searchText)
                }
            }
        },
        methods: {
            viewWine(wine) {
                this.$router.push({
                    name: 'WineMasteryWineRoute',
                    params: {
                        id: wine.id,
                        wine: wine
                    },
                    query: {
                        fromSearch: true
                    }
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
.search-page-background {
  background: url('~@/assets/common/background_image.png');
  width: 100%;
}

.light-text {
  text-align: center;
  padding-top: 50px;
  margin-bottom: 50px;
  font-family: CFALight;
  color: #7f2f2d;
  font-size: clamp(30px, 4.6vw, 50px);
}

.bold-text {
  font-family: CFABold;
}

.search-container {
  padding-bottom: 100px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  overflow: auto;
  height: 100vh;
}

</style>
