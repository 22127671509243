import axios from 'axios'
import wineService from '@/services/wine-service'

export default {
    state: {
        wineSearchResults: [],
        wineSearchQuery: '',
        cancelToken: {}
    },
    mutations: {
        setResult(state, wines) {
            state.wineSearchResults = wines
        },
        setWineSearchQuery(state, query) {
            state.wineSearchQuery = query
        },
        setCancelToken(state, token) {
            state.cancelToken = token
        }
    },
    actions: {
        async searchWines({ commit, state }, query) {
            const CancelToken = axios.CancelToken
            const source = CancelToken.source()

            if (state.cancelToken.cancel) {
                state.cancelToken.cancel('')
            }
            const filterObject = {
                q: query,
                published: true
            }
            const wines = (await wineService.getAll({ filter: filterObject }, source.token)).content

            commit('setCancelToken', source)
            commit('setResult', wines)
        }
    },
    getters: {
        wineSearchResults: (state) => {
            return state.wineSearchResults
        },
        wineSearchQuery: (state) => {
            return state.wineSearchQuery
        }
    }
}
