<template>
    <div class="wine-info-background">
        <div class="nav-back-container ml-2">
            <div v-if="$route.name !== 'WineMasteryRoute' && $route.name !== 'SpiritMasteryRoute'" class="bck-btn mr-4" @click="goBack()">
                <img :src="require('@/assets/common/goback_image.png')">
            </div>
            <div class="bck-btn" @click="goHome()">
                <img :src="require('@/assets/common/home-button.png')">
            </div>
        </div>
        <div v-if="wine" class="main-details-container mt-4">
            <div class="wine-details-container">
                <product-gallery :images="wine.avatarPhotos" />
                <wine-details-table :wine="wine" class="wine-details" />
            </div>
            <div class="wine-description-container">
                <p>
                    {{ wine.extraSpecialInfo | nullCheck }}
                </p>
            </div>
            <food-pairing :wine="wine" />
            <p class="carousel-title">- {{ $t('WineInfoRoute.recommendedTitle') }} -</p>
            <wine-carousel
                :wines="recommendedWines"
                route-prefix="/wine-roads"
            />
        </div>
        <enjoy-responsibly />
        <disclaimer />
    </div>
</template>

<script>
    import wineService from '@/services/wine-service'
    import statisticsService from '@/services/statistics-service'
    import WineCarousel from '@/components/WineCarousel'
    import FoodPairing from '@/components/FoodPairing'
    import WineDetailsTable from '@/components/WineDetailsTable'
    import ProductGallery from '@/components/ProductGallery'
    import randomizeItems from '@/utils/randomize-items'
    import wineRegionService from '@/services/wine-region-service'
    import Disclaimer from '@/components/Disclaimer'
    import EnjoyResponsibly from '@/components/EnjoyResponsibly'

    export default {
        components: {
            WineCarousel,
            FoodPairing,
            WineDetailsTable,
            ProductGallery,
            Disclaimer,
            EnjoyResponsibly
        },
        beforeRouteUpdate(to, from, next) {
            this.init(to.params.id)

            next()
        },
        data() {
            return {
                recommendedWines: [],
                wine: null
            }
        },
        async mounted() {
            this.init(this.$route.params.id)
        },
        methods: {
            async init(id) {
                this.wine = await wineService.get(id)
                this.fetchRecommendedWines()
                this.submitVisited()
                const wineRegion = await wineRegionService.getWineRegionById(this.wine.wineRegion.id)
                this.wineRegion = wineRegion
            },
            async fetchRecommendedWines() {
                this.recommendedWines = []
                if (this.wine.wineColor === null) {
                    return
                }
                const filterObject = {
                    idNot: this.wine.id,
                    wineColor: this.wine.wineColor.id,
                    published: true,
                    swodOnly: false
                }

                const fetchedWines = (await wineService.getAll({ filter: filterObject, range: [0, 100], applyClustering: false })).content
                this.recommendedWines = randomizeItems(fetchedWines)
            },
            submitVisited() {
                statisticsService.wineVisited(this.wine.id)
            },
            goBack() {
                return this.$router.go(-1)
            },
            goHome() {
                this.$router.push({ path: '/home' })
            }

        }
    }
</script>

<style scoped>
.wine-info-background {
    background: url('~@/assets/wine-mastery/background_wineinfo.jpg');
    background-size: cover;
    width: 100%;
    height: 100%;
}

.nav-back-container{
    display:flex;
    align-items: center;
    padding-top: 2rem;
}

button:focus {outline:0!important;}
.bck-btn {
  display: flex;
  align-items: center;
  color: #59595b;
}

.main-details-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.wine-details-container {
  display: flex;
}

.wine-details {
    align-self: center;
}
.wine-description-container {
    columns: 2 200px;
    height: 14rem;
    width: 90%;
    margin: 0 auto;
}
.carousel-title {
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    color: #ac3846;
}

</style>
